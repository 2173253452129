import React from 'react'
import Box from '@material-ui/core/Box'
import SEO from '../components/seo'
import Layout from '../components/layout/layout'
import Header from '../components/header'
import MobileNav from '../components/mobileNav'

const NotFoundPage = () => (
  <>
    <Header />
    <MobileNav />
    <Layout>
      <SEO title="404: Not found" />
      <Box
        height="85vh"
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        alignItems="center"
        py={12}
        bgcolor="#E8E8EE"
      >
        <Box
          order={1}
          width={4 / 5}
          textAlign="center"
          fontSize="4rem"
          letterSpacing={2}
          lineHeight={1.5}
        >
          NOT FOUND :(
        </Box>
        <Box
          order={2}
          width={4 / 5}
          fontSize="2rem"
          letterSpacing={2}
          lineHeight={1.5}
          mt={5}
          textAlign="center"
        >
          You just hit a page that doesn&#39;t exist... the sadness.
        </Box>
      </Box>
    </Layout>
  </>
)

export default NotFoundPage
